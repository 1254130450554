<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="eshopSubscriptionProduct_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-subscription-product-filter></app-subscription-product-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="products"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import SubscriptionProductFilter from './SubscriptionProductFilter'
import DataTable from '../shared/DataTable'
import PeriodTypeMixin from '../mixins/valueObject/eshop/PeriodTypeMixin'
import VisibilityTypeMixin from '../mixins/valueObject/eshop/VisibilityTypeMixin'
import ButtonCreate from '../shared/ButtonCreate'
import StoreService from '../../services/eshop/StoreService'
import ProductTypeMixin from '@/components/mixins/valueObject/eshop/ProductTypeMixin'

export default {
  name: 'SubscriptionProductList',
  mixins: [
    PeriodTypeMixin,
    VisibilityTypeMixin,
    ProductTypeMixin
  ],
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopSubscriptionProduct.id'),
          name: this.$t('eshopSubscriptionProduct.name'),
          period: this.$t('eshopSubscriptionProduct.period'),
          priceInEuro: this.$t('eshopSubscriptionProduct.price'),
          visibility: this.$t('eshopSubscriptionProduct.visibility'),
          enabled: this.$t('eshopSubscriptionProduct.enabled'),
          publishedSince: this.$t('eshopSubscriptionProduct.published_since'),
          publishedUntil: this.$t('eshopSubscriptionProduct.published_until')
        },
        actions: {
          edit: 'eshopSubscriptionProduct_edit'
        },
        render: {
          enabled: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          period: data => this.prettyPeriod(data),
          visibility: data => this.prettyVisibility(data)
        },
        highlightRow: record => this.isNotActive(record)
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    products () {
      return this.$store.getters['eshopSubscriptionProduct/list']
    },
    totalCount () {
      return this.$store.getters['eshopSubscriptionProduct/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['eshopSubscriptionProduct/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appSubscriptionProductFilter: SubscriptionProductFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getProducts () {
      this.$store.dispatch('eshopSubscriptionProduct/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('eshopSubscriptionProduct/setPage', page)
      this.getProducts()
    }
  },
  mounted () {
    this.page = this.$store.getters['eshopSubscriptionProduct/page']
    this.getProducts()
    StoreService.fetchAll()
  }
}
</script>

<style lang="scss">

.table-responsive {
  td:nth-child(2) {
    min-width: 200px;
  }
}

.table .highlight-row {
  background-color: #f5c6cb !important;
}

</style>
